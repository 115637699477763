<template>
  <div>
    <h4 class="text-center mt-5">Logging out...</h4>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch("auth/logOut").then(() => {
      this.$router.push({ name: "login" });
    });
  },
  methods: {}
};
</script>
